import { render, staticRenderFns } from "./WillInstructions.vue?vue&type=template&id=3baea1f5"
import script from "./WillInstructions.vue?vue&type=script&lang=js"
export * from "./WillInstructions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports