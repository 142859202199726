<template>
  <div>
    <div :class="{ completed: progress.complete }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"></div>
      <h4 class="trans-section-title">Lasting Power of Attorney Instructions</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <b-badge> {{ progress.percentage }}% Complete</b-badge>

        <p class="mb-lg-32">
          Detailed instructions need to be taken for each recommendation. Click on each product / service to complete
          your instructions.
        </p>
        <div v-if="!previousSectionComplete">
          <b-alert show variant="warning">
            <i class="i-Lock-2 link text-primary"/>
            This section is currently locked as the previous section is incomplete.
          </b-alert>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-20 trans-section-body">
      <b-col md="4">

        <LpaHealthCard
            :disabled="!previousSectionComplete"
            :transaction="transaction"
            :progress="progress.lpaHealthInstructionsProgress"/>
      </b-col>
      <b-col md="4">
        <LpaFinancialCard
            :disabled="!previousSectionComplete"
            :transaction="transaction"
            :progress="progress.lpaFinancialInstructionsProgress"/>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import LpaHealthCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/lpa/LpaHealth";
import LpaFinancialCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/lpa/LpaFinancial";

export default {
  name: "LpaInstructions",
  components: {
    LpaFinancialCard,
    LpaHealthCard
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true
    }
  }
};
</script>
