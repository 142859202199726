import {clientHelpers} from "@/mixins/clientHelpers";
import {dateFormat} from "@/mixins/dateFormat";
import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";
import {willPeopleHelpers} from "@/components/common/questionnaires/fieldsets/will/helpers/willPeopleHelpers";
import {personHelpers} from "@/mixins/personHelpers";

export const progressEstatePlanningCouponHelpers = {
    name: 'progressEstatePlanningCouponHelpers',
    mixins: [clientHelpers, dateFormat, showClientQuestionHelpers, willPeopleHelpers, personHelpers],

    data() {
        return {}
    },
    computed: {
        // transaction ordered and simplified
        client() {
            return this.transaction.clients.find(client => client.id === this.user.is_client.id)
        },
        estatePlanningSurvey() {
            return this.transaction.estate_planning_survey
        },
        will() {
            return this.transaction.wills.sort((a, b) => new Date(a.created_date) - new Date(b.created_date))[0]
        },
        lpa() {
            let sorted = this.transaction.lpas.sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
            if (sorted.length) return sorted[0]
            return null
        },
        // will helpers
        childrenYoung() {
            return this.client.people.filter(person => this.isChildOf(person))
        },

        // progress
        // all progress
        progress() {
            let sectionsRequired = 2
            let allPercentage = this.clientProfileProgress.percentage + this.estatePlanningProgress.percentage

            if (this.willProgress.required) {
                allPercentage += this.willProgress.percentage
                sectionsRequired += 1
            }
            if (this.lpaProgress.required) {
                allPercentage += this.lpaProgress.percentage
                sectionsRequired += 1
            }

            let percentage = +Number(allPercentage / sectionsRequired).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                complete: percentage === 100
            }
        },

        // client profile overall
        clientProfileProgress() {
            // summary of progress for all client sections
            let allPercentage = this.clientProfileBasic.percentage + this.clientProfileAdditional.percentage + this.clientProfileChildren.percentage + this.clientProfileFinancial.percentage
            let percentage = +Number(allPercentage / 4).toFixed(0)
            let errors = [...this.clientProfileBasic.errors, ...this.clientProfileAdditional.errors, ...this.clientProfileChildren.errors, ...this.clientProfileFinancial.errors]

            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                complete: percentage === 100,
                client: this.client,
                clientProfileBasic: this.clientProfileBasic,
                clientProfileAdditional: this.clientProfileAdditional,
                clientProfileChildren: this.clientProfileChildren,
                clientProfileFinancial: this.clientProfileFinancial
            }
        },
        // client profile questionnaire sections
        clientProfileBasic() {
            // return percentage complete for basic client profile section
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client

            // add questions and percentages
            questions += 1
            if (client.profileNameTitle) completed += 1
            else errors.push('Title not answered')

            questions += 1
            if (client.profileNameFirst) completed += 1
            else errors.push('First name not answered')

            questions += 1
            if (client.profileNameLast) completed += 1
            else errors.push('Last name not answered')

            // questions += 1
            // if (tF.includes(client.profileAliasYN)) completed += 1
            // else errors.push('Also known as not answered')

            // if (client.profileAliasYN) {
            //     questions += 1
            //     if (client.profileAlias) {
            //         completed += 1
            //     } else errors.push('Also known as detail not answered')
            // }
            //
            // questions += 1
            // if (tF.includes(client.profilePreferredNameYN)) {
            //     completed += 1
            //
            //     if (client.profilePreferredNameYN) {
            //         questions += 1
            //         if (client.profilePreferredName) {
            //             completed += 1
            //         } else errors.push('Preferred name not answered')
            //     }
            //
            // } else errors.push('Preferred name yes/no not answered')

            // questions += 1
            // if (tF.includes(client.profilePreviousNameYN)) completed += 1
            // else errors.push('Previous name not answered')

            // if (client.profilePreviousNameYN) {
            //     // skipp-able questions (may want commenting out)
            //     questions += 3
            //     if (client.profilePreviousName) {
            //         completed += 1
            //     } else errors.push('Previous name details not answered')
            //     if (client.profilePreviousNameChangedDate) {
            //         completed += 1
            //     } else errors.push('Previous name date not answered')
            //     if (client.profilePreviousNameChangedMethod) {
            //         completed += 1
            //     } else errors.push('Previous name method not answered')
            // }

            questions += 1
            if (client.profileSex) completed += 1
            else errors.push('Gender not answered')

            questions += 1
            if (client.profileDOB) completed += 1
            else errors.push('Date of birth not answered')

            // questions += 1
            // if (client.profileNationality) completed += 1
            // else errors.push('Nationality not answered')
            //
            // questions += 1
            // if (tF.includes(client.profileDiplomaticImmunity)) completed += 1
            // else errors.push('Diplomatic status not answered')


            questions += 1
            if (client.profileRelationshipStatus) completed += 1
            else errors.push('Relationship status not answered')

            questions += 1
            if (tF.includes(client.profileRelationshipCurrentlyYN)) {
                completed += 1

                if (client.profileRelationshipCurrentlyYN) {
                    questions += 1
                    if (client.profileRelationshipCurrentStatus) completed += 1
                    else errors.push('Client current relationship status not answered')

                    questions += 1
                    if (client.profileRelationshipDuration) completed += 1
                    else errors.push('Client Relationship duration not answered')
                }

            } else errors.push('Currently in a relationship not answered')

            questions += 1
            if (tF.includes(client.profileRelationshipPreviousYN)) {
                completed += 1
                if (client.profileRelationshipPreviousYN) {
                    questions += 1
                    if (client.profileRelationshipPreviousStatus) completed += 1
                    else errors.push('Previous relationship ending not answered')

                    questions += 1
                    if (client.profileRelationshipPreviousPersonName) completed += 1
                    else errors.push('Previous relationship person name not answered')
                }
            } else errors.push('Been in a previous marriage not answered')

            // questions += 1
            // if (client.profileMobileNumber) completed += 1
            // else errors.push('Mobile number not answered')

            questions += 1
            if (client.profileEmail) completed += 1
            else errors.push('Email not answered')

            questions += 1
            if (tF.includes(client.profileMarketingConsent)) completed += 1
            else errors.push('Marketing consent not answered')

            questions += 1
            if (client.current_address) completed += 1
            else errors.push('Current address is not answered')


            // if (client.profileAddressMovedIn) completed += 1
            // else errors.push('Address moved in date not answered')
            //
            // if (client.profileAddressOccupancy) completed += 1
            // else errors.push('Address occupancy not answered')
            //
            // if (tF.includes(client.profileAddressCorrespondenceYN)) completed += 1
            // else errors.push('Address correspondence not answered')

            // let now = new Date()
            // let addressHistoryYearsRequired = 5
            // if (client.profileAddressMovedIn &&
            //     now.setFullYear(now.getFullYear() - addressHistoryYearsRequired) <
            //     new Date(client.profileAddressMovedIn)) {
            //     questions += 1
            //     if (client.previous_address && client.previous_address.length) completed += 1
            //     else errors.push('Previous address history not answered')
            // }
            //

            // calculate percentage
            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client}
        },
        clientProfileAdditional() {
            // return percentage complete for basic client profile section
            let result = {}

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client

            // add questions and percentages

            questions += 1
            if (client.profileAliasYN !== null) completed += 1
            else errors.push('Also known as not answered')

            if (client.profileAliasYN) {
                questions += 1
                if (client.profileAlias) {
                    completed += 1
                } else errors.push('Also known as detail not answered')
            }

            questions += 1
            if (client.profilePreferredNameYN !== null) {
                completed += 1

                if (client.profilePreferredNameYN) {
                    questions += 1
                    if (client.profilePreferredName) {
                        completed += 1
                    } else errors.push('Preferred name not answered')
                }

            } else errors.push('Preferred name yes/no not answered')

            questions += 1
            if (client.profilePreviousNameYN !== null) completed += 1
            else errors.push('Previous name not answered')

            questions += 1
            if (client.profileNationality) completed += 1
            else errors.push('Nationality not answered')

            questions += 1
            if (client.profilePrimaryEmployment) completed += 1
            else errors.push('Primary employment not answered')

            questions += 1
            if (client.profileSecondaryEmploymentYN !== null) {
                completed += 1
                if (client.profileSecondaryEmploymentYN) {
                    questions += 1
                    if (client.profileSecondaryEmployment) completed += 1
                    else errors.push('Secondary employment not answered')
                }
            }
            else errors.push('Secondary employment yes/no missing')

            questions += 1
            if (client.profileRetirementAge) completed += 1
            else errors.push('Retirement age not answered')

            questions += 1
            if (client.profileDiplomaticImmunity !== null) completed += 1
            else errors.push('Diplomatic status not answered')

            questions += 1
            if (client.pets !== null) completed += 1
            else errors.push('Pets yes/no not answered')

            // calculate percentage
            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client}
        },
        clientProfileChildren() {
            // return percentage complete for children/dependants client profile section
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client

            // add questions and percentages
            questions += 1
            if (tF.includes(client.children_have)) completed += 1
            else errors.push('Have children not answered')

            if (client.children_have) {
                questions += 1
                if (client.people && client.people.filter(p => this.isSonOrDaughter(p)).length) completed += 1
                else errors.push('Children details not answered')
            }

            questions += 1
            if (tF.includes(client.profilePregnant)) completed += 1
            else errors.push('Expecting a child not answered')

            if (client.profilePregnant) {
                questions += 1
                if (client.profilePregnantDueDate) {
                    completed += 1
                } else errors.push('Expecting a child date not answered')
            }

            questions += 1
            if (tF.includes(client.dependants_have)) completed += 1
            else errors.push('Client other dependants not answered')

            if (client.dependants_have) {
                questions += 1
                if (client.people && client.people.filter(p => this.isDependant(p)).length) completed += 1
                else errors.push('Dependants details not answered')
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client}
        },
        clientProfileFinancial() {
            // return percentage complete for finacial client profile section
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client

            questions += 1
            if (tF.includes(client.assets_other_high_value)) completed += 1
            else errors.push('Other high value assets not answered')

            if (client.assets_other_high_value) {
                questions += 1
                if (client.assets && client.assets.filter(a => !this.assetsOtherNot.includes(a.assetType)).length) completed += 1
                else errors.push('Other asset details not answered')
            }

            questions += 1
            if (tF.includes(client.policies_other_type)) completed += 1
            else errors.push('Other policies not answered')

            if (client.policies_other_type) {
                questions += 1
                if (client.policies && client.policies.filter(p => !this.policiesOthersNot.includes(p.policyType)).length) completed += 1
                else errors.push('Other policies details not answered')
            }

            questions += 1
            if (tF.includes(client.mortgage_debts)) completed += 1
            else errors.push('Mortgage debts not answered')

            if (client.mortgage_debts) {
                questions += 1
                if (client.liabilities && client.liabilities.filter(l => this.liabilitiesMortgage.includes(l.liabilityType)).length) completed += 1
                else errors.push('Mortgage details not answered')
            }

            questions += 1
            if (tF.includes(client.unsecured_loans)) completed += 1
            else errors.push('Unsecured loans not answered')

            if (client.unsecured_loans) {
                questions += 1
                if (client.liabilities && client.liabilities.filter(l => this.liabilitiesLoans.includes(l.liabilityType)).length) completed += 1
                else errors.push('Unsecured loan details not answered')
            }

            questions += 1
            if (tF.includes(client.credit_card_balances)) completed += 1
            else errors.push('Credit cards not answered')

            if (client.credit_card_balances) {
                questions += 1
                if (client.liabilities && client.liabilities.filter(l => this.liabilitiesCreditCards.includes(l.liabilityType)).length) completed += 1
                else errors.push('Credit card details not answered')
            }

            questions += 1
            if (tF.includes(client.other_credit_agreements)) completed += 1
            else errors.push('Other credit agreements not answered')

            if (client.other_credit_agreements) {
                questions += 1
                if (client.liabilities && client.liabilities.filter(l => !this.liabilitiesOtherNot.includes(l.liabilityType)).length) completed += 1
                else errors.push('Other credit agreements details not answered')
            }

            // questions += 1
            // if (tF.includes(client.profileBankruptcyIVAYN)) completed += 1
            // else errors.push('Bankruptcy not answered')
            //
            // questions += 1
            // if (tF.includes(client.profileDebtManagementYN)) completed += 1
            // else errors.push('Debt management not answered')
            //
            // questions += 1
            // if (tF.includes(client.profileCCJYN)) completed += 1
            // else errors.push('CCJ not answered')
            //
            // questions += 1
            // if (tF.includes(client.profileMissedYN)) completed += 1
            // else errors.push('Missed payment not answered')
            //
            // if (client.commitments && client.commitments.length) completed += 1
            // else errors.push('Commitments details not answered')

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client}
        },

        // estate planning survey overall
        estatePlanningProgress() {
            // summary of progress for all client sections
            let sectionsRequired = 2 // health status and existing products are required
            let allPercentage = this.estatePlanningExistingProducts.percentage + this.estatePlanningHealthStatus.percentage
            let errors = [...this.estatePlanningExistingProducts.errors, ...this.estatePlanningHealthStatus.errors]

            if (this.estatePlanningBusiness.required) {
                allPercentage += this.estatePlanningBusiness.percentage
                sectionsRequired += 1
                errors = [...errors, ...this.estatePlanningBusiness.errors]
            }
            if (this.estatePlanningDivorce.required) {
                allPercentage += this.estatePlanningDivorce.percentage
                sectionsRequired += 1
                errors = [...errors, ...this.estatePlanningDivorce.errors]
            }

            let percentage = +Number(allPercentage / sectionsRequired).toFixed(0)

            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                complete: percentage === 100,
                client: this.client,
                estatePlanningBusiness: this.estatePlanningBusiness,
                estatePlanningDivorce: this.estatePlanningDivorce,
                estatePlanningExistingProducts: this.estatePlanningExistingProducts,
                estatePlanningHealthStatus: this.estatePlanningHealthStatus
            }
            // distribution
            // if (this.willDistributionResidentialPartner(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_residential_partner)) completed += 1
            //     else errors.push('Distribution to partner status not answered')
            // }
            // if (this.willDistributionTenantsInCommon(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_tenants_in_common)) completed += 1
            //     else errors.push('Distribution tenants in common status not answered')
            // }
            // if (this.willDistributionPartnerResideDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_partner_reside_details) completed += 1
            //     else errors.push('Distribution partner reside details not answered')
            // }
            // if (this.willDistributionResidentialChildrenAfter(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_residential_children_after)) completed += 1
            //     else errors.push('Distribution to children status not answered')
            // }
            // if (this.willDistributionEstateToDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_estate_to_details) completed += 1
            //     else errors.push('Distribution to details not answered')
            // }
            // if (this.willDistributionOtherAssetsToPartner(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_other_assets_to_partner)) completed += 1
            //     else errors.push('Distribution other assets to partner status not answered')
            // }
            // if (this.willDistributionOtherAssetsDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_other_assets_details) completed += 1
            //     else errors.push('Distribution other assets to details not answered')
            // }
            // if (this.willDistributionAllChildrenBeneficiaries(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_all_children_beneficiaries)) completed += 1
            //     else errors.push('Distribution children all are beneficiaries status not answered')
            // }
            // if (this.willDistributionChildrenExcludedDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_children_excluded_details) completed += 1
            //     else errors.push('Distribution children excluded details not answered')
            // }
            // if (this.willDistributionOtherPropertiesOccupiedFriends(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_other_properties_occupied_friends)) completed += 1
            //     else errors.push('Distribution other properties occupied by friends status not answered')
            // }
            // if (this.willDistributionOtherPropertiesOccupiedFriendsDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_other_properties_occupied_friends_details) completed += 1
            //     else errors.push('Distribution other properties occupied by friends details not answered')
            // }
            // if (this.willDistributionOtherPropertiesOccupiedFriendsContinue(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_other_properties_occupied_friends_continue)) completed += 1
            //     else errors.push('Distribution other properties occupied by friends to continue status not answered')
            // }
            // if (this.willDistributionBeneficiariesIllnessWorking(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_illness_working)) completed += 1
            //     else errors.push('Distribution beneficiaries illness status not answered')
            // }
            // if (this.willDistributionBeneficiariesIllnessWorkingDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_illness_working_details) completed += 1
            //     else errors.push('Distribution beneficiaries illness details not answered')
            // }
            // if (this.willDistributionBeneficiariesManagingFinances(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_managing_finances)) completed += 1
            //     else errors.push('Distribution beneficiaries difficulty managing finances status not answered')
            // }
            // if (this.willDistributionBeneficiariesManagingFinancesDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_managing_finances_details) completed += 1
            //     else errors.push('Distribution beneficiaries difficulty managing finances details not answered')
            // }
            // if (this.willDistributionBeneficiariesFinancialVulnerable(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_financial_vulnerable)) completed += 1
            //     else errors.push('Distribution beneficiaries financially vulnerable status not answered')
            // }
            // if (this.willDistributionBeneficiariesFinancialVulnerableDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_financial_vulnerable_details) completed += 1
            //     else errors.push('Distribution beneficiaries financially vulnerable details not answered')
            // }
            // if (this.willDistributionBeneficiariesStateBenefits(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_state_benefits)) completed += 1
            //     else errors.push('Distribution beneficiaries state benefits status not answered')
            // }
            // if (this.willDistributionBeneficiariesStateBenefitsDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_state_benefits_details) completed += 1
            //     else errors.push('Distribution beneficiaries state benefits details not answered')
            // }
            // if (this.willDistributionBeneficiariesDrugs(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_drugs)) completed += 1
            //     else errors.push('Distribution beneficiaries drug problem status not answered')
            // }
            // if (this.willDistributionBeneficiariesDrugsDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_drugs_details) completed += 1
            //     else errors.push('Distribution beneficiaries drug problem details not answered')
            // }
            // if (this.willDistributionBeneficiariesDivorced(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_beneficiaries_divorced)) completed += 1
            //     else errors.push('Distribution beneficiaries divorced status not answered')
            // }
            // if (this.willDistributionBeneficiariesDivorcedDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_beneficiaries_divorced_details) completed += 1
            //     else errors.push('Distribution beneficiaries divorced details not answered')
            // }
            // if (this.willDistributionEstateTaxable(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_estate_taxable)) completed += 1
            //     else errors.push('Distribution taxable estate status not answered')
            // }
            // if (this.willDistributionPensionCollecting(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.will_distribution_pension_collecting)) completed += 1
            //     else errors.push('Distribution pension collecting status not answered')
            // }
            // if (this.willDistributionPensionNominationDetails(survey)) {
            //     questions += 1
            //     if (survey.will_distribution_pension_nomination_details) completed += 1
            //     else errors.push('Distribution pension nomination details not answered')
            // }
            // insurance
            // if (this.lifeInsurance(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.life_insurance_all_in_trust)) completed += 1
            //     else errors.push('Life insurance all in trust status not answered')
            //
            //     questions += 1
            //     if (tF.includes(survey.life_insurance_professionally_arranged)) completed += 1
            //     else errors.push('Life insurance professionally arranged status not answered')
            //
            //     if (survey.life_insurance_professionally_arranged === false) {
            //         questions += 1
            //         if (survey.life_insurance_arranged_details) completed += 1
            //         else errors.push('Life insurance arranged by details not answered')
            //     }
            //
            //     questions += 1
            //     if (tF.includes(survey.life_insurance_reviewed_recently)) completed += 1
            //     else errors.push('Life insurance last reviewed status not answered')
            //
            // } else {
            //     questions += 1
            //     if (tF.includes(survey.life_insurance_none)) completed += 1
            //     else errors.push('No life insurance status not answered')
            // }
            // // financial advisor
            // if (this.financialAdvisor(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.financial_advisor)) completed += 1
            //     else errors.push('Financial advisor status not answered')
            //
            // }
            //
            // // mortgage
            // if (this.mortgageEarlyRepaymentCharge(survey)) {
            //     questions += 1
            //     if (tF.includes(survey.mortgage_early_repayment_charge)) completed += 1
            //     else errors.push('Mortgage early repayment change status not answered')
            // }
        },
        // estate planning sections
        estatePlanningBusiness() {
            // return percentage complete for each client profile
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let survey = this.estatePlanningSurvey
            let client = this.client

            // business
            if (this.businessContinue(survey)) {
                questions += 1
                if (tF.includes(survey.business_continue)) completed += 1
                else errors.push('Existing business to continue status not answered')
            }

            if (this.businessOtherShareHolders(survey)) {
                questions += 1
                if (tF.includes(survey.business_other_shareholders)) completed += 1
                else errors.push('Existing business other shareholders status not answered')
            }

            if (this.businessShareHoldersAgreement(survey)) {
                questions += 1
                if (tF.includes(survey.business_shareholders_agreement)) completed += 1
                else errors.push('Existing business shareholders agreement status not answered')
            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage: isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                estatePlanningSurvey: survey,
                required: this.businessContinue(survey) || this.businessOtherShareHolders(survey) || this.businessShareHoldersAgreement(survey) || this.businessShareHoldersAgreement(survey)
            }

        },
        estatePlanningDivorce() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let survey = this.estatePlanningSurvey
            let client = this.client

            // relationship
            // divorce
            if (client.profileRelationshipStatus === 'divorced' || client.profileRelationshipPreviousStatus === 'divorced') {
                questions += 1
                if (tF.includes(survey.divorce_spouse_remarried)) completed += 1
                else errors.push('Spouse remarried not answered')

                if (survey.divorce_spouse_remarried === false) {
                    questions += 1
                    if (tF.includes(survey.divorce_consent_order_signed)) completed += 1
                    else errors.push('Consent order signed not answered')
                }

                if (survey.divorce_consent_order_signed === false) {
                    questions += 1
                    if (client.profileRelationshipPreviousPersonName) completed += 1
                    else errors.push('Previous partner name not answered')
                }

            }

            // separation
            if (client.profileRelationshipStatus === 'separated' || client.profileRelationshipPreviousStatus === 'separated') {
                questions += 1
                if (tF.includes(survey.separation_divorce_proceedings_started)) completed += 1
                else errors.push('Divorce proceedings started not answered')

                questions += 1
                if (tF.includes(survey.separation_agreement_signed)) completed += 1
                else errors.push('Divorce consent order signed not answered')

                questions += 1
                if (client.profileRelationshipPreviousPersonName) completed += 1
                else errors.push('Previous partner name not answered')

            }
            // widowed
            if (client.profileRelationshipStatus === 'widowed' || client.profileRelationshipPreviousStatus === 'widowed') {
                questions += 1
                if (survey.widowed_year_of_death) completed += 1
                else errors.push('Widowed; year of death not answered')

                questions += 1
                if (tF.includes(survey.widowed_distribution_to_other)) completed += 1
                else errors.push('Widowed; distribution left to others YN not answered')

                if (survey.widowed_distribution_to_other) {
                    questions += 1
                    if (survey.widowed_distribution_to_other_details) completed += 1
                    else errors.push('Widowed; distribution to others details not answered')
                }
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {
                percentage: isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                estatePlanningSurvey: survey
            }
        },
        estatePlanningHealthStatus() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let survey = this.estatePlanningSurvey
            let client = this.client

            // health
            questions += 1
            if (tF.includes(client.health_status)) completed += 1
            else errors.push('Health illness status not answered')

            if (client.health_status) {
                questions += 1
                if (client.health_issues) completed += 1
                else errors.push('Health illness details not answered')
            }

            questions += 1
            if (tF.includes(client.capacity_status)) completed += 1
            else errors.push('Health capacity status not answered')

            if (client.capacity_status) {
                questions += 1
                if (client.capacity_issues) completed += 1
                else errors.push('Health capacity details not answered')
            }

            questions += 1
            if (tF.includes(client.mobility_status)) completed += 1
            else errors.push('Health mobility status not answered')

            if (client.mobility_status) {
                questions += 1
                if (client.mobility_issues) completed += 1
                else errors.push('Health mobility details not answered')
            }

            questions += 1
            if (tF.includes(client.language_status)) completed += 1
            else errors.push('Health language status not answered')

            if (client.language_status) {
                questions += 1
                if (client.language_issues) completed += 1
                else errors.push('Health language details not answered')
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {
                percentage: isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                estatePlanningSurvey: survey
            }

        },
        estatePlanningExistingProducts() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let survey = this.estatePlanningSurvey
            let client = this.client


            // existing will
            questions += 1
            if (tF.includes(survey.will_existing)) completed += 1
            else errors.push('Existing products Will status not answered')

            if (survey.will_existing) {
                questions += 1
                if (survey.will_existing_drafts_person) completed += 1
                else errors.push('Existing Will drafts person not answered')

                questions += 1
                if (survey.will_existing_year) completed += 1
                else errors.push('Existing Will drafts person not answered')

                questions += 1
                if (tF.includes(survey.changes_relationship)) completed += 1
                else errors.push('Existing Will relationship change status not answered')

                if (survey.changes_relationship) {
                    questions += 1
                    if (survey.changes_relationship_details) completed += 1
                    else errors.push('Existing Will relationship change details not answered')
                }

                questions += 1
                if (tF.includes(survey.changes_dependants)) completed += 1
                else errors.push('Existing Will changes to dependants status not answered')

                if (survey.changes_dependants) {
                    questions += 1
                    if (survey.changes_dependants_details) completed += 1
                    else errors.push('Existing Will relationship change details not answered')
                }

                questions += 1
                if (tF.includes(survey.changes_appointed)) completed += 1
                else errors.push('Existing Will changes to appointed people status not answered')

                if (survey.changes_appointed) {
                    questions += 1
                    if (survey.changes_appointed_details) completed += 1
                    else errors.push('Existing Will changes to appointed people details not answered')
                }

                questions += 1
                if (tF.includes(survey.changes_estate)) completed += 1
                else errors.push('Existing Will changes to estate status not answered')

                if (survey.changes_estate) {
                    questions += 1
                    if (survey.changes_estate_details) completed += 1
                    else errors.push('Existing Will changes to estate details not answered')
                }

                questions += 1
                if (tF.includes(survey.changes_will_other)) completed += 1
                else errors.push('Existing Will other changes status not answered')

                if (survey.changes_will_other) {
                    questions += 1
                    if (survey.changes_will_other_details) completed += 1
                    else errors.push('Existing Will other changes details not answered')
                }

                if (this.willExistingOverseas(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_existing_overseas)) completed += 1
                    else errors.push('Existing Will overseas status not answered')
                }
            }

            // existing lpa
            questions += 1
            if (tF.includes(survey.lpa_existing)) completed += 1
            else errors.push('Existing Lpa status not answered')

            if (survey.lpa_existing) {
                questions += 1
                if (survey.lpa_existing_type) completed += 1
                else errors.push('Existing Lpa type not answered')

                questions += 1
                if (tF.includes(survey.lpa_changes)) completed += 1
                else errors.push('Existing Lpa changes status not answered')

                if (survey.lpa_changes) {
                    questions += 1
                    if (survey.lpa_changes_details) completed += 1
                    else errors.push('Existing Lpa changes details not answered')
                }
            }

            // existing funeral
            if (this.funeralPlanHas(survey)) {
                questions += 1
                if (tF.includes(survey.funeral_plan_has)) completed += 1
                else errors.push('Existing funeral plan status not answered')

                if (this.funeralPlanFunds(survey)) {
                    questions += 1
                    if (tF.includes(survey.funeral_plan_funds)) completed += 1
                    else errors.push('Existing funeral funds status not answered')
                }
            }


            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {
                percentage: isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                estatePlanningSurvey: survey
            }
        },

        // product progress
        productProgress () {
            let sectionsRequired = 0
            let allPercentage = 0

            if (this.willProgress.required) {
                allPercentage += this.willProgress.percentage
                sectionsRequired += 1
            }
            if (this.lpaProgress.required) {
                allPercentage += this.lpaProgress.percentage
                sectionsRequired += 1
            }

            let percentage = +Number(allPercentage / sectionsRequired).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                complete: percentage === 100
            }
        },
        // will overall progress
        willProgress() {
            let sectionsRequired = 5

            // summary of progress for all client sections
            let allPercentage = this.willFuneralProgress.percentage + this.willExecutorsProgress.percentage +
                this.willTrusteesProgress.percentage + this.willDistributionProgress.percentage +
                this.willGiftsProgress.percentage


            let errors = [...this.willFuneralProgress.errors, ...this.willExecutorsProgress.errors,
                ...this.willTrusteesProgress.errors, ...this.willDistributionProgress.errors,
                this.willGiftsProgress.errors]

            if (this.willGuardiansProgress.required) {
                allPercentage += this.willGuardiansProgress.percentage
                errors = [...errors, ...this.willGuardiansProgress.errors]
                sectionsRequired += 1
            }

            if (this.willPetsProgress.required) {
                allPercentage += this.willPetsProgress.percentage
                errors = [...errors, ...this.willPetsProgress.errors]
                sectionsRequired += 1
            }

            let percentage = +Number(allPercentage / sectionsRequired).toFixed(0)

            return {
                required: !!this.will,
                complete: percentage === 100,
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: this.client,
                willGuardiansProgress: this.willGuardiansProgress,
                willPetsProgress: this.willPetsProgress,
                willFuneralProgress: this.willFuneralProgress,
                willExecutorsProgress: this.willExecutorsProgress,
                willTrusteesProgress: this.willTrusteesProgress,
                willDistributionProgress: this.willDistributionProgress,
                willGiftsProgress: this.willGiftsProgress
            }
        },
        // will sections progress
        willGuardiansProgress() {
            // return percentage complete for each client profile
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let required = !!this.childrenYoung.length

            if (required) {
                questions += 1
                if (tF.includes(will.data.guardians.details.separateGuardians)) completed += 1
                else errors.push('Do you want to appoint Guardians?')
                if (will.data.guardians.details.separateGuardians) {
                    questions += 1
                    if (tF.includes(will.data.guardians.details.individual)) completed += 1
                    else errors.push('Guardians separate or all the same for each child?')

                    if (will.data.guardians.details.individual) {
                        questions += 1
                        if (will.data.guardians.details.children.length &&
                            will.data.guardians.details.children.filter(child => child.guardians.primary.length).length === will.data.guardians.details.children.length) {
                            completed += 1

                            questions += 1
                            if (will.data.guardians.details.children.length &&
                                will.data.guardians.details.children.filter(child => tF.includes(child.guardians.secondaryYN)).length ===
                                will.data.guardians.details.children.length) {
                                completed += 1

                                questions += 1
                                if (will.data.guardians.details.children.filter(child => child.guardians.secondary.length).length ===
                                    will.data.guardians.details.children.filter(child => child.guardians.secondaryYN).length) completed += 1
                                else errors.push('There is a child without secondary guardians')


                                questions += 1
                                if (will.data.guardians.details.children.filter(child => tF.includes(child.guardians.backupYN)).length ===
                                    will.data.guardians.details.children.length) {
                                    completed += 1

                                    questions += 1
                                    if (will.data.guardians.details.children.filter(child => child.guardians.backup.length).length ===
                                        will.data.guardians.details.children.filter(child => child.guardians.backupYN).length) completed += 1
                                    else errors.push('There is a child without backup guardians')
                                } else errors.push('There is a child without a backup guardian yes/no answered')
                            } else errors.push('There is a child without a secondary guardian yes/no answered')
                        } else errors.push('There is a child without a primary guardian')

                    } else if (will.data.guardians.details.individual === false) {
                        questions += 1
                        if (will.data.guardians.details.primary.length) {
                            completed += 1
                        } else errors.push('No primary guardians')

                        questions += 1
                        if (will.data.guardians.details.secondaryYN) completed += 1
                        else errors.push('Secondary guardians yes/no not answered')

                        if (will.data.guardians.details.secondaryYN) {
                            questions += 1
                            if (will.data.guardians.details.secondary.length) {
                                completed += 1
                            } else errors.push('No secondary guardians')
                        }
                    }


                }
            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage: isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                will: will,
                required: required
            }
        },
        willPetsProgress() {
            let result = {}

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let pets = will.data.pets.details
            let required = !!client.pets

            questions += 1
            if (pets.separatePets !== null) completed += 1
            else errors.push('Pets yes/no not answered')

            if (pets.separatePets) {
                questions += 1
                if (pets.primary.length) completed += 1
                else errors.push('Primary pet carers not answered')

                if (pets.primary.length) {
                    questions += 1
                    if (pets.secondaryYN !== null) completed += 1
                    else errors.push('Secondary pet carers yes/no not answered')

                    if (pets.secondaryYN) {
                        questions += 1
                        if (pets.secondary.length) completed += 1
                        else errors.push('Secondary pet carers not answered')
                    }
                }
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                will: will,
                required: required
            }
        },
        willFuneralProgress() {
            let result = {}
            let tF = [true, false]

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let funeral = will.data.funeral.details

            questions += 1
            if (funeral.typeOption) completed += 1
            else errors.push('Type of funeral not answered')

            questions += 1
            if (funeral.donorOption) completed += 1
            else errors.push('Donor option not answered')

            questions += 1
            if (funeral.styleOptions.length) completed += 1
            else errors.push('Funeral style option not answered')

            questions += 1
            if (tF.includes(funeral.customWishesYN)) completed += 1
            else errors.push('Custom wishes yes/no not answered')

            if (funeral.customWishesYN) {
                questions += 1
                if (funeral.customWishesOption) completed += 1
                else errors.push('Custom wishes not answered')
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client, will: will}
        },
        willExecutorsProgress() {
            let result = {}
            // let tF = [true, false]

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let executors = will.data.executors.details

            questions += 1
            if (executors.typeOfExecutors) {
                completed += 1

                questions += 1
                if (executors.primary.length) completed += 1
                else errors.push('Primary executors not selected')

                if (executors.typeOfExecutors !== 'professional') {
                    questions += 1
                    if (executors.secondaryYN !== null) {
                        completed += 1
                        if (executors.secondaryYN) {
                            questions += 1
                            if (executors.secondary.length) {
                                completed += 1

                                questions += 1
                                if (executors.backupYN !== null) {
                                    completed += 1
                                    if (executors.backupYN) {
                                        questions += 1
                                        if (executors.backup.length) completed += 1
                                        else errors.push('Backup executors not selected')
                                    }
                                } else errors.push('Backup executors yes/no not answered')

                            } else errors.push('Secondary executors not selected')
                        }
                    } else errors.push('Secondary executors yes/no not answered')
                }

            } else errors.push('Type of executors not answered')

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client, will: will}
        },
        willTrusteesProgress() {

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let trustees = this.will.data.trustees.details

            let result = {
                percentage: 100,
                errors: [],
                client: client,
                will: will
            }

            if (!this.will) return result
            // if (!this.will || !trustees.enabled) return result

            questions += 1
            if (trustees.separateTrustees !== null) completed += 1
            else errors.push('Assign separate trustees not answered')

            if (trustees.separateTrustees) {
                questions += 1
                if (trustees.primary.length) completed += 1
                else errors.push('No primary trustees')

                if (trustees.primary.length) {
                    questions += 1
                    if (trustees.secondaryYN !== null) completed += 1
                    else errors.push('Secondary trustees yes/no not answered')

                    if (trustees.secondaryYN) {
                        questions += 1
                        if (trustees.secondary.length) completed += 1
                        else errors.push('No secondary trustees')
                    }

                    if (trustees.secondary.length) {
                        questions += 1
                        if (trustees.backupYN !== null) completed += 1
                        else errors.push('Backup trustees yes/no not answered')

                        if (trustees.backupYN) {
                            questions += 1
                            if (trustees.backup.length) completed += 1
                            else errors.push('No backup trustees')
                        }
                    }
                }
            }

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client, will: will}
        },
        willDistributionProgress() {
            let result = {}
            // let tF = [true, false]

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let distributionPrimary = will.data.distributionPrimary.details
            let distributionSecondary = will.data.distributionSecondary.details
            let distributionBackup = will.data.distributionBackup.details

            questions += 1
            if (distributionPrimary.shares.length) {
                completed += 1
                for (let i = 0; i < distributionPrimary.shares.length; i++) {
                    let share = distributionPrimary.shares[i]

                    questions += 1
                    if (share.recipients.length) completed += 1
                    else errors.push(`Share of ${share.share}% missing recipients`)

                    for (let x = 0; x < share.recipients.length; x++) {
                        let recipient = share.recipients[x]

                        if (['person', 'group'].includes(recipient.type)) {
                            questions += 1

                            if (share.stirpes !== null) {
                                completed += 1
                            } else errors.push(`Primary distribution for ${this.listPeopleAddressDob(
                                {people: share.recipients, noDob: true})}; stirpes yes/no is missing`)


                            console.log('share.stirpes', share.stirpes)

                            if (share.stirpes) {
                                questions += 1
                                if (share.stirpesAge) completed += 1
                                else errors.push(`Primary distribution for ${this.listPeopleAddressDob(
                                    {people: share.recipients, noDob: true})}; stirpes age is missing`)

                            }

                            if (distributionPrimary.shares.length > 1 && recipient.type === 'person') {
                                // should shares pass to the other beneficiaries shares
                                questions += 1

                                if (share.backupsYN !== null) completed += 1
                                else errors.push(`Primary distribution for ${this.listPeopleAddressDob(
                                    {
                                        people: share.recipients,
                                        noDob: true
                                    })}; backup to people in other shares is missing`)

                                if (share.backupsYN) {
                                    // name other beneficiaries for backup
                                    questions += 1
                                    if (recipient.recipients.length) completed += 1
                                    else errors.push(`Primary distribution for ${this.listPeopleAddressDob(
                                        {
                                            people: share.recipients,
                                            noDob: true
                                        })}; backup to specific people are missing`)

                                }
                            }
                        }
                    }
                }
            } else errors.push('No primary distribution shares')

            // questions += 1
            if (distributionSecondary.shares.length) {
                // completed += 1
                for (let i = 0; i < distributionSecondary.shares.length; i++) {
                    let share = distributionSecondary.shares[i]

                    for (let x = 0; x < share.recipients.length; x++) {
                        let recipient = share.recipients[x]

                        if (['person', 'group'].includes(recipient.type)) {

                            questions += 1
                            if (share.stirpes !== null) {
                                completed += 1
                            } else errors.push(`Secondary distribution for ${this.listPeopleAddressDob(
                                {people: share.recipients, noDob: true})}; stirpes yes/no is missing`)

                            if (share.stirpes) {
                                questions += 1
                                if (share.stirpesAge) completed += 1
                                else errors.push(`Secondary distribution for ${this.listPeopleAddressDob(
                                    {people: share.recipients, noDob: true})}; stirpes age is missing`)

                            }

                            if (distributionSecondary.shares.length > 1 && recipient.type === 'person') {
                                // should shares pass to the other beneficiaries shares
                                questions += 1

                                if (share.backupsYN !== null) completed += 1
                                else errors.push(`Secondary distribution for ${this.listPeopleAddressDob(
                                    {
                                        people: share.recipients,
                                        noDob: true
                                    })}; backup to people in other shares is missing`)

                                if (share.backupsYN) {
                                    // name other beneficiaries for backup
                                    questions += 1

                                    if (recipient.recipients.length) completed += 1
                                    else errors.push(`Secondary distribution for ${this.listPeopleAddressDob(
                                        {
                                            people: share.recipients,
                                            noDob: true
                                        })}; backup to specific people are missing`)

                                }
                            }


                        }
                    }

                }


            } else errors.push('No secondary distribution shares')

            questions += 1
            if (distributionBackup.backupYN !== null) {
                completed += 1

                if (distributionBackup.backupYN) {
                    questions += 1
                    if (distributionBackup.shares.length) {
                        completed += 1
                        for (let i = 0; i < distributionBackup.shares.length; i++) {
                            let share = distributionBackup.shares[i]

                            for (let x = 0; x < share.recipients.length; x++) {
                                let recipient = share.recipients[x]

                                if (['person', 'group'].includes(recipient.type)) {
                                    questions += 1
                                    if (share.stirpes !== null) {
                                        completed += 1
                                    } else errors.push(`Backup distribution for ${this.listPeopleAddressDob(
                                        {people: share.recipients, noDob: true})}; stirpes yes/no is missing`)

                                    if (share.stirpes) {
                                        questions += 1
                                        if (share.stirpesAge) completed += 1
                                        else errors.push(`Backup distribution for ${this.listPeopleAddressDob(
                                            {people: share.recipients, noDob: true})}; stirpes age is missing`)

                                    }

                                    if (distributionBackup.shares.length > 1 && recipient.type === 'person') {
                                        // should shares pass to the other beneficiaries shares
                                        questions += 1
                                        if (share.backupsYN !== null) completed += 1
                                        else errors.push(`Backup distribution for ${this.listPeopleAddressDob(
                                            {
                                                people: share.recipients,
                                                noDob: true
                                            })}; backup to people in other shares is missing`)

                                        if (share.backupsYN) {
                                            // name other beneficiaries for backup
                                            questions += 1
                                            if (recipient.recipients.length) completed += 1
                                            else errors.push(`Backup distribution for ${this.listPeopleAddressDob(
                                                {
                                                    people: share.recipients,
                                                    noDob: true
                                                })}; backup to specific people are missing`)
                                        }
                                    }
                                }
                            }
                        }
                    } else errors.push('No backup distribution shares')
                }
            } else errors.push('Backup distribution yes/no not answered')

            percentage = Number(Number((completed / questions) * 100).toFixed(0))
            return {percentage:  isNaN(percentage) ? 0 : percentage, errors: errors, client: client, will: will}
        },
        willGiftsProgress() {
            let result = {}

            // guards
            if (!this.will) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let will = this.will
            let client = this.client
            let giftsFirst = will.data.giftsFirst.details
            let giftsSecond = will.data.giftsSecond.details
            // primary gifts

            questions += 1
            if (giftsFirst.giftsYN !== null) completed += 1
            else errors.push('Direct gifts yes/no not answered')

            if (giftsFirst.giftsYN) {
                questions += 1
                if (giftsFirst.gifts.length) completed += 1
                else errors.push('No direct gifts left')
            }

            if (will.data.distributionPrimary.details.partnerPrimaryYN) {
                // secondary gifts must have a partner in sole distribution
                questions += 1
                if (giftsSecond.giftsYN !== null) completed += 1
                else errors.push('Second death gifts yes/no not answered')


                if (giftsSecond.giftsYN) {
                    questions += 1
                    if (giftsSecond.gifts.length) completed += 1
                    else errors.push('No second death gifts left')
                }


            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                will: will
            }
        },

        // lpa overall progress
        lpaProgress() {
            // summary of progress for all client sections
            let allPercentage = this.lpaHealthInstructionsProgress.percentage + this.lpaFinancialInstructionsProgress.percentage
            let percentage = +Number(allPercentage / 2).toFixed(0)
            let errors = [...this.lpaHealthInstructionsProgress.errors, ...this.lpaFinancialInstructionsProgress.errors]

            return {
                required: !!this.lpa,
                complete: percentage === 100,
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: this.client,
                lpaHealthInstructionsProgress: this.lpaHealthInstructionsProgress,
                lpaFinancialInstructionsProgress: this.lpaFinancialInstructionsProgress
            }
        },
        // lpa sections progress
        lpaHealthInstructionsProgress() {
            let result = {}

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client
            if (this.lpa) {
                let lpa = this.lpa.data

                questions += 1
                if (lpa.attorneys.health.details.primary.length) completed += 1
                else errors.push('No Primary attorneys selected')

                questions += 1
                if (lpa.attorneys.health.details.backupYN !== null) {
                    completed += 1
                    if (lpa.attorneys.health.details.backupYN) {
                        questions += 1
                        if (lpa.attorneys.health.details.backup.length) completed += 1
                        else errors.push('No backup attorneys selected')
                    }
                } else errors.push('Backup attorney yes/no not answered')

                questions += 1
                if (lpa.certificateProviders.health.details.primary.length) completed += 1
                else errors.push('No certificate provider selected')

                questions += 1
                if (lpa.attorneys.health.details.generalOptions.health_life_sustaining_treatment) completed += 1
                else errors.push('Refuse consent to life sustaining treatment not answered')

                questions += 1
                if (lpa.attorneys.health.details.generalOptions.instructionsYN !== null) {
                    completed += 1

                    if (lpa.attorneys.health.details.generalOptions.instructionsYN) {
                        questions += 1
                        if (lpa.attorneys.health.details.generalOptions.instructions) completed += 1
                        else errors.push('Optional instructions details are not answered')
                    }
                } else errors.push('Optional instructions yes/no not answered')

                questions += 1
                if (lpa.attorneys.health.details.generalOptions.preferencesYN !== null) {
                    completed += 1

                    if (lpa.attorneys.health.details.generalOptions.preferencesYN) {
                        questions += 1
                        if (lpa.attorneys.health.details.generalOptions.preferences) completed += 1
                        else errors.push('Preferences instructions details are not answered')
                    }
                } else errors.push('Optional preferences yes/no not answered')


                questions += 1
                if (lpa.notifiedPeople.health.details.enabled !== null) {
                    completed += 1

                    if (lpa.notifiedPeople.health.details.enabled) {
                        questions += 1
                        if (lpa.notifiedPeople.health.details.primary.length) completed += 1
                        else errors.push('No notified people listed')
                    }
                } else errors.push('Notified people yes/no not answered')
            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                lpa: this.lpa}
        },
        lpaFinancialInstructionsProgress() {
            let result = {}

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0 // questions completed
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client

            if (this.lpa) {
                let lpa = this.lpa.data

                questions += 1
                if (lpa.attorneys.property.details.primary.length) completed += 1
                else errors.push('No Primary attorneys selected')

                questions += 1
                if (lpa.attorneys.property.details.backupYN !== null) {
                    completed += 1
                    if (lpa.attorneys.property.details.backupYN) {
                        questions += 1
                        if (lpa.attorneys.property.details.backup.length) completed += 1
                        else errors.push('No backup attorneys selected')
                    }
                } else errors.push('Backup attorney yes/no not answered')

                questions += 1
                if (lpa.certificateProviders.property.details.primary.length) completed += 1
                else errors.push('No certificate provider selected')

                questions += 1
                if (lpa.attorneys.property.details.generalOptions.authority_when) completed += 1
                else errors.push('Authority to act when is not answered')

                questions += 1
                if (lpa.attorneys.property.details.generalOptions.instructionsYN !== null) {
                    completed += 1

                    if (lpa.attorneys.property.details.generalOptions.instructionsYN) {
                        questions += 1
                        if (lpa.attorneys.property.details.generalOptions.instructions) completed += 1
                        else errors.push('Optional instructions details are not answered')
                    }
                } else errors.push('Optional instructions yes/no not answered')

                questions += 1
                if (lpa.attorneys.property.details.generalOptions.preferencesYN !== null) {
                    completed += 1

                    if (lpa.attorneys.property.details.generalOptions.preferencesYN) {
                        questions += 1
                        if (lpa.attorneys.property.details.generalOptions.preferences) completed += 1
                        else errors.push('Preferences instructions details are not answered')
                    }
                } else errors.push('Optional preferences yes/no not answered')

                questions += 1
                if (lpa.notifiedPeople.property.details.enabled !== null) {
                    completed += 1

                    if (lpa.notifiedPeople.property.details.enabled) {
                        questions += 1
                        if (lpa.notifiedPeople.property.details.primary.length) completed += 1
                        else errors.push('No notified people listed')
                    }
                } else errors.push('Notified people yes/no not answered')
            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client,
                lpa: this.lpa
            }
        },
        // checkout overall
        checkoutProgress() {
            // summary of progress for all client sections
            let allPercentage = this.checkoutQuestionnaireProgress.percentage
            let percentage = +Number(allPercentage).toFixed(0)
            let errors = [...this.checkoutQuestionnaireProgress.errors]

            return {
                required: true,
                complete: percentage === 100,
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: this.client,
                checkoutQuestionnaireProgress: this.checkoutQuestionnaireProgress,
            }
        },
        // checkout questionnaire
        checkoutQuestionnaireProgress() {
            let result = {}

            // guards
            if (!this.transaction) return result

            // init variable
            let completed = 0
            let questions = 0 // amount of required questions
            let percentage = 0
            let errors = []
            let client = this.client
            if (this.transaction.checkout) {
                let checkout = this.transaction.checkout

                questions += 1
                if (checkout.storage !== null) completed += 1
                else errors.push('Do you require document storage')

                questions += 1
                if (checkout.printing !== null) completed += 1
                else errors.push('Do you require printing')

                questions += 1
                if (checkout.advised_service !== null) completed += 1
                else errors.push('Do you require an advised service')
            }

            percentage = +Number((completed / questions) * 100).toFixed(0)
            return {
                percentage:  isNaN(percentage) ? 0 : percentage,
                errors: errors,
                client: client
            }
        },

    },
    methods: {}
}
