<template>
  <div>
    <component
        v-if="transaction"
        :is="transactionType"
        :transaction="transaction"
        @refreshTransaction="fetchTransaction"
    />
  </div>
</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'

import TransactionEstatePlanningDetail from './estatePlanning/index'


export default {
  name: 'ClientTransactionCouponDetail',
  created() {
    this.fetchTransaction()
    this.pageLoadToastMessage()
  },
  mixins: [toast],
  components: {
    TransactionEstatePlanningDetail,
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    transactionType() {
      if (this.transaction) {
        if (this.transaction.type === 'estate_planning') return 'TransactionEstatePlanningDetail'

      }
      return null
    }
  },
  data() {
    return {
      transaction: null
    }
  },
  methods: {
    fetchTransaction() {
      http.get('client_transaction_detail',
          {params: {id: this.$route.params.id}}).then(response => {
        this.transaction = response.data
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>
