<template>
  <div :class="{ 'trans-section-locked': !previousSectionComplete }">
    <div :class="{ completed: progress.complete }"
         class="d-flex align-items-center page-num-container pt-80"
    >
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4>Estate Planning Questions</h4>
    </div>
    <b-row>
      <b-col lg="7">
        <p class="mb-lg-32">
          The below sections contain questions about your health, estate planning priorities and existing estate planning arrangements. Once completed you can move on the the will specific questions in Part 3.
        </p>
        <p v-if="!previousSectionComplete">
          This section is currently locked as the previous section (About You) is incomplete.
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-20 trans-section-body">
      <b-col
          v-if="businessContinue(progress.estatePlanningBusiness.estatePlanningSurvey) ||
            businessOtherShareHolders(progress.estatePlanningBusiness.estatePlanningSurvey) ||
            businessShareHoldersAgreement(progress.estatePlanningBusiness.estatePlanningSurvey)"
          md="4"
      >
        <EstatePlanningSurveyBusinessCard
            :progress="progress.estatePlanningBusiness"
            :transaction="transaction"
        />
      </b-col>
      <b-col
          v-if="separated(progress.estatePlanningDivorce.estatePlanningSurvey) ||
          widowed(progress.estatePlanningDivorce.estatePlanningSurvey) ||
          divorced(progress.estatePlanningDivorce.estatePlanningSurvey)"
          md="4"
      >
        <EstatePlanningSurveyDivorceCard
            :progress="progress.estatePlanningDivorce"
            :transaction="transaction"
        />
      </b-col>
      <b-col
          md="4"
      >
        <EstatePlanningSurveyHealthStatusCard
            :progress="progress.estatePlanningHealthStatus"
            :transaction="transaction"
        />
      </b-col>
      <b-col
          md="4"
      >
        <EstatePlanningSurveyExistingProductsCard
            :progress="progress.estatePlanningExistingProducts"
            :transaction="transaction"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";
import EstatePlanningSurveyBusinessCard
  from "@/views/client/transaction/detail/coupon/estatePlanning/cards/estatePlanningSurvey/EstatePlanningSurveyBusiness";
import EstatePlanningSurveyDivorceCard
  from "@/views/client/transaction/detail/coupon/estatePlanning/cards/estatePlanningSurvey/EstatePlanningSurveyDivorce";
import EstatePlanningSurveyHealthStatusCard
  from "@/views/client/transaction/detail/coupon/estatePlanning/cards/estatePlanningSurvey/EstatePlanningSurveyHealthStatus";
import EstatePlanningSurveyExistingProductsCard
  from "@/views/client/transaction/detail/coupon/estatePlanning/cards/estatePlanningSurvey/EstatePlanningSurveyExistingProducts";

export default {
  name: "EstatePlanning",
  mixins: [showClientQuestionHelpers],
  components: {
    EstatePlanningSurveyExistingProductsCard,
    EstatePlanningSurveyHealthStatusCard, EstatePlanningSurveyDivorceCard, EstatePlanningSurveyBusinessCard
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
