<template>
  <b-col md="7">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Your Coupon Overview</span>
      </h4>
    </div>
    <p class="pr-20" style="margin-top: -30px">
      You have been assign this coupon <strong>{{ coupon.code }}</strong> from
      <strong>{{ coupon.created_by.name }}</strong> at <strong>{{ coupon.created_by.is_introducer.introducer }}</strong>
    </p>
    <p>You are entitled to <strong>{{ coupon.discount_percentage}}%</strong> off a
      <strong>{{ coupon.discount_service.client_description }}</strong>, which was originally priced at <strong>{{ currencyInvoice(coupon.discount_service.gross_price) }}</strong>
    </p>
  </b-col>
</template>

<script>
import { currencyFormat } from "@/mixins/currencyFormat";

export default {
  name: "Introduction",
  mixins: [currencyFormat],
  props: {
    coupon: {
      type: Object,
      required: true
    }
  }
};
</script>
