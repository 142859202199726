<template>
  <b-card body-class="p-0"
          class="hover-down trans-card" @click="goto">
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img :src="image"
               alt="cardImage">
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5>Guardians</h5>
        </div>
      </div>
      <div
          class="cursor-pointer pr-2"
          @click.stop="goto"><i class="text-25 text-secondary i-Next1"></i></div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="progress.percentage" variant="primary"></b-progress-bar>
    </div>

    <div class="d-flex align-items-center justify-content-start pl-3 pr-1 pt-12 pb-12">
      <div :class="status[1]" class="badge text-10 p-1">{{ status[0] }}</div>
    </div>

    <div
        v-if="progress.percentage > 50 && progress.errors.length"
        class="pl-3 pr-3">
      <b-alert
          show
          variant="alert alert-card alert-danger pl-0">
        <ul class="list-square-small">
          <li v-for="(error, index) in progress.errors" :key="index">{{ error }}</li>
        </ul>
      </b-alert>
    </div>
  </b-card>

</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'WillGuardiansCard',
  mixins: [clientHelpers],
  props: {
    progress: {
      type: Object,
      required: true
    },
    transaction: {
      type: Object,
      required: true
    },

  },
  methods: {
    goto() {
      if (this.disabled) return console.log('disabled')
      this.$router.push({name: 'WillSimpleGuardians',
        params: {
          transactionId: this.transaction.id,
          clientId: this.progress.client.id,
          willId: this.progress.will.id
        }
      }).catch(
          error => {
            console.log(error)
          }
      )
    }
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = 'Begin'
      let variant = 'badge-primary'
      if (this.progress.percentage === 100) {
        string = 'Complete'
        variant = 'badge-success'
      }
      else if (this.progress.percentage < 10) {
        string = 'Begin'
        variant = 'badge-warning'
      }
      else {
        string = 'In Progress'
        variant = 'badge-warning'
      }
      return [string, variant]
    },
    image() {
      return require('@/assets/images/will-guardianship.svg')
    }
  }
}


</script>
