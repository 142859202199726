<template>
  <div>
    <div
        :class="{completed: progress.complete}"
        class="d-flex align-items-center page-num-container pt-80"
    >
      <div class="page-num" :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
      </div>

      <h4>About You</h4>
    </div>
    <b-row>
      <b-col lg="7">
        <p class="mb-lg-32">
          Start by clicking on each of the questionnaires below and answering the questions about yourself and your family. Once each section is at 100% you can move on to Part 2 (Estate Planning Questions).
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-20">
      <b-col md="4">
        <ClientBasic
            :progress="progress.clientProfileBasic"
            :transaction="transaction"
            @refreshTransaction="$emit('refreshTransaction', $event)"
        />
      </b-col>
      <b-col md="4">
        <ClientDependants
            :progress="progress.clientProfileChildren"
            :transaction="transaction"
            @refreshTransaction="$emit('refreshTransaction', $event)"
        />
      </b-col>
      <b-col md="4">
        <ClientFinancial
            :progress="progress.clientProfileFinancial"
            :transaction="transaction"
            @refreshTransaction="$emit('refreshTransaction', $event)"
        />

      </b-col>
      <b-col md="4">
        <ClientAdditional
            :progress="progress.clientProfileAdditional"
            :disabled="false"
            :transaction="transaction"
            @refreshTransaction="$emit('refreshTransaction', $event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClientBasic from "@/views/client/transaction/detail/coupon/estatePlanning/cards/clientProfile/ClientBasic";
import ClientDependants from "@/views/client/transaction/detail/coupon/estatePlanning/cards/clientProfile/ClientDependants";
import ClientFinancial from "@/views/client/transaction/detail/coupon/estatePlanning/cards/clientProfile/ClientFinancial";
import ClientAdditional
  from "@/views/client/transaction/detail/coupon/estatePlanning/cards/clientProfile/ClientAdditional";

export default {
  name: "ClientProfile",
  components: {ClientAdditional, ClientFinancial, ClientDependants, ClientBasic},
  props: {
    progress: {
      type: Object,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
