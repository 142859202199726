<template>
  <div :class="{ 'trans-section-locked': !previousSectionComplete }">
    <div :class="{ completed: progress.complete }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Will Instructions</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          The details needed to create your Will can be added in the various sections below. Each section needs to be 100% complete in order to complete this part of the process.
        </p>
        <p v-if="!previousSectionComplete">
          This section is currently locked as the previous section (Estate Planning Questions) is incomplete.
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-20 trans-section-body">
      <b-col md="4">
        <WillExecutorsCard
            :progress="progress.willExecutorsProgress" :transaction="transaction"/>
      </b-col>
      <b-col md="4">
        <WillTrusteesCard
            :progress="progress.willTrusteesProgress" :transaction="transaction"/>
      </b-col>
      <b-col v-if="progress.willGuardiansProgress.required" md="4">
        <WillGuardiansCard
            :progress="progress.willGuardiansProgress" :transaction="transaction"/>
      </b-col>
      <b-col md="4">
        <WillDistributionCard
            :progress="progress.willDistributionProgress" :transaction="transaction"/>
      </b-col>
      <b-col md="4">
        <WillGiftsCard
            :progress="progress.willGiftsProgress" :transaction="transaction"/>
      </b-col>
      <b-col v-if="progress.willPetsProgress.required" md="4">
        <WillPetsCard
            :progress="progress.willPetsProgress" :transaction="transaction"/>
      </b-col>
      <b-col md="4">
        <WillFuneralCard
            :progress="progress.willFuneralProgress" :transaction="transaction"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import WillGuardiansCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillGuaridans";
import WillFuneralCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillFuneral";
import WillPetsCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillPets";
import WillDistributionCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillDistribution";
import WillExecutorsCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillExtecutors";
import WillTrusteesCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillTrustees";
import WillGiftsCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/will/WillGifts";

export default {
  name: "WillInstructions",
  components: {
    WillGiftsCard,
    WillTrusteesCard,
    WillExecutorsCard,
    WillDistributionCard,
    WillPetsCard,
    WillFuneralCard,
    WillGuardiansCard
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true
    }
  }
};
</script>
