<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="hero"
            class="trans-background"
            src="@/assets/images/trans-banner.png"
        />
        <div class="container">
          <h1 class="text-white">Will Overview</h1>
          <h3 class="arrow-before text-white pl-30">{{ user.name }}<br/>
          </h3>
        </div>
      </div>
    </div>

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <introduction :coupon="transaction.coupon"/>
        </b-row>
      </b-container>
    </div>

    <StepsProgress
        :transaction="transaction"
        :client-profile-progress="clientProfileProgress"
        :estate-planning-progress="estatePlanningProgress"
        :will-progress="willProgress"
        :lpa-progress="lpaProgress"
        :checkout-progress="checkoutProgress"
    />

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">

          <ClientProfile
              :progress="clientProfileProgress"
              :transaction="transaction"
          />

          <EstatePlanning
              :progress="estatePlanningProgress"
              :transaction="transaction"
              :previousSectionComplete="clientProfileProgress.complete"
          />

          <WillInstructions
              v-if="will"
              :progress="willProgress"
              :transaction="transaction"
              :previousSectionComplete="this.clientProfileProgress.complete && this.estatePlanningProgress.complete"
          />

          <LpaInstructions
              v-if="lpa"
              :progress="lpaProgress"
              :transaction="transaction"
              :previousSectionComplete="this.clientProfileProgress.complete && this.estatePlanningProgress.complete"
          />

          <Checkout
              :transaction="transaction"
              :progress="checkoutProgress"
              :previousSectionComplete="this.clientProfileProgress.complete && this.estatePlanningProgress.complete && productProgress.complete"
          />

          <b-badge>Overall: {{ progress.percentage }}% Complete</b-badge>


          <!--          <Appointments-->
          <!--              ref="appointments"-->
          <!--              :transaction="transaction"-->
          <!--              :showBookingButton="noFutureAppointments"-->
          <!--              help-text="These are your scheduled appointments. From here you can reschedule appointment if necessary."-->
          <!--              appointment-type="Estate Planning Consultation"-->
          <!--              host-role="Estate Planning Introducer"-->
          <!--              @refresh="$emit('refreshTransaction', $event)"-->
          <!--          />-->

          <!--          <document-request-section-->
          <!--              v-model="transaction"-->
          <!--              title="Below are details of documents requested by your estate planner."-->
          <!--          />-->

          <!--          <Documents :transaction="transaction" />-->

        </div>
      </b-container>
    </div>
    <!--    <Contact-->
    <!--        class="mt-80 mb-80"-->
    <!--        :transaction="transaction"-->
    <!--        @refreshTransaction="$emit('refreshTransaction', $event)"-->
    <!--    />-->
  </div>
</template>

<script>
import {
  progressEstatePlanningCouponHelpers
} from "@/views/client/transaction/detail/coupon/estatePlanning/progressEstatePlanningCouponHelpers";

// import DocumentRequestSection from "@/components/common/documents/requests/DocumentRequestSection";
import Introduction from "./Introduction.vue";
// import Appointments from "@/components/common/appointment";
// import Contact from "@/components/common/contact/Contact";
// import Documents from "@/views/client/transaction/detail/estatePlanning/Documents";
import ClientProfile from "@/views/client/transaction/detail/coupon/estatePlanning/sections/ClientProfile";
import EstatePlanning from "@/views/client/transaction/detail/coupon/estatePlanning/sections/EstatePlanning";
import WillInstructions from "@/views/client/transaction/detail/coupon/estatePlanning/sections/WillInstructions";
import LpaInstructions from "@/views/client/transaction/detail/coupon/estatePlanning/sections/LpaInsutructions";
import Checkout from "@/views/client/transaction/detail/coupon/estatePlanning/sections/Checkout";
import StepsProgress from "@/views/client/transaction/detail/coupon/estatePlanning/StepsProgress";

export default {
  name: "TransactionEstatePlanningDetail",
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  mixins: [progressEstatePlanningCouponHelpers],
  components: {
    StepsProgress,
    Checkout,
    LpaInstructions,
    WillInstructions,
    EstatePlanning,
    ClientProfile,
    // Documents,
    // Contact,
    // Appointments,
    // DocumentRequestSection,
    Introduction,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    noFutureAppointments() {
      return !this.transaction.events.filter(event => event.status === 'Scheduled' && new Date(event.endDateTime) > new Date()).length > 0
    }
  },
  data() {
    return {};
  },
};
</script>
