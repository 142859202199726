<template>
  <div :class="{ 'trans-section-locked': !previousSectionComplete }">
    <div :class="{ completed: progress.complete }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Checkout</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p v-if="!previousSectionComplete">
          This section is currently locked as the previous section is incomplete.
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-20 trans-section-body">
      <b-col md="4">
        <CheckoutCard
            :progress="progress.checkoutQuestionnaireProgress"
            :transaction="transaction"/>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import CheckoutCard from "@/views/client/transaction/detail/coupon/estatePlanning/cards/Checkout";

export default {
  name: "Checkout",
  components: {
    CheckoutCard
  },
  props: {
    progress: {
      type: Object,
      required: false,
    },
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true
    }
  }
};
</script>
